$('#vchrCountry select').change(function changeCountry() {
  $.get('/ajax/getStates.cfm?country=' + $(this).val(), function getStates(
    data
  ) {
    $('#vchrState select')
      .html(data)
      .prop('disabled', false);
  });
});
$('#form-left form').submit(function checkRequired() {
  var fail = false;
  var message = '';
  var errorCount = 0;
  var name = '';
  var title = '';
  $('#form-left form [required]').each(function checkFields(index, element) {
    var $element = $(element);
    if ($element.val() === '') {
      $element.css('background', 'red');
      fail = true;
      errorCount++;
      name = $element.siblings('label').replace('*', '');
      message += name + ' is required. ';
    }
  });
  if (fail) {
    title = $(this).attr('data-title');
    $('#modal')
      .html(
        '<p>Form submission failed for the following reason(s):' +
          message +
          '</p>'
      )
      .dialog({
        minHeight: 150,
        width: 300,
        modal: true,
        title: title,
        closeText: 'X',
        buttons: {
          Okay: function closeModal() {
            $(this).dialog('close');
          }
        }
      });
    return false;
  }
  return true;
});
