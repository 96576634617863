$('.js-trim-select').on('change', function() {
  var value = $(this).val();
  var url = '/ajax/connector.cfc?method=setBodyType&strBodyType=' + value;
  $.ajax({
    method: 'POST',
    url: url
  }).done(function() {
    window.location.href = '/accessory-brands/';
  });
});

$('.js-filterCapType').change(function changeMake() {
  $('#accessory-search form').submit();
});
