if($('#packages').length) {
  console.log('packages activetesd');
  $('.open-models').on('click',function(){
    let icon = $(this).find('i');
    let models = $(this).next();
    if(models.hasClass('active')) {
      //models.removeClass('active').fadeOut();
      models.removeClass('active',500);
      icon.addClass('fa-caret-right').removeClass('fa-caret-down');  
    } else {
      //models.addClass('active').fadeIn();
      models.addClass('active',500);
      icon.addClass('fa-caret-down').removeClass('fa-caret-right');
    }
  });

  // set active
  const queryString = window.location.search;
  const pathName = window.location.pathname.split('/');
  console.log(pathName);
  let make = '';
  let model = '';
  let sort = ''

  console.log(window.location)
  console.log(queryString);
  // create variables
  if(pathName.length) {
    const urlParams = new URLSearchParams(queryString);
    sort = urlParams.get('sort');

    make = pathName[2];
    model = pathName[3];
    
    
    console.log('am i here?');
  } else if(queryString.length) {
    const urlParams = new URLSearchParams(queryString);
    model = urlParams.get('model');
    //console.log(model);
    make = urlParams.get('make');
    sort = urlParams.get('sort');
    //console.log(sort);
    console.log('noped, me querystinged')
  };
console.log(make);
console.log(model);
console.log(sort);
  // loop through filters
  let models = $('.filter .vehicle-model');
  //console.log(models);
  models.each(function(){
    let vehicle = $(this).find('a');
    let vehicleMake = vehicle.data('make');
    let vehicleModel = vehicle.data('model');
    console.log('vehicle make: ',vehicleMake);
    console.log('vehicle modellll: ', vehicleModel);
    if(vehicleMake == make && vehicleModel == model) {
      $(this).parent().addClass('active');
      vehicle.addClass('active');
    }
  });

  $('#sort').change(function(){
    const urlParams = new URLSearchParams(queryString);
    const pathName = window.location.pathname.split('/');
    let selectedSort = $(this).find('option:selected').val();
    let html = window.location.pathname + '?';

    if(typeof selectedSort !== "undefined" && selectedSort !== "null") {
      html = html + 'sort=' + selectedSort;
    }
/*
    if(queryString.length) {
      const model = urlParams.get('model');
      if(model) {
        if(typeof model !== "undefined" && typeof model !== "null") {
          html = html + '&model=' + model;
        }
      }
    }
    */
    $('#sortForm').attr('action',html);
    location.href = html;
  });
}