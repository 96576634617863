if ($('#wheel').length) {  

  // toggles
  function toggleInfo(content,button) {
    if(content.style.display === 'none') {
      content.style.display = 'block';
      button.classList.add('active');
    } else {
      content.style.display = 'none';
      button.classList.remove('active');
    }
  }
  // vehicle info toggle
  var ymmInfo = document.querySelector('.ymm-info');
  var ymmButton = document.querySelector('.buy-toggle'); // add to cart button
  if(ymmInfo && ymmButton) {
    ymmInfo.style.display = 'none';
    ymmButton.addEventListener('click',function(){
      toggleInfo(ymmInfo,ymmButton)
    });
  }

  // finance toggle
  /*
  var finInfo = document.querySelector('.financing-options');
  var finButton = document.querySelector('.financing-toggle');
  finInfo.style.display = 'none';
  finButton.addEventListener('click',function(){
    toggleInfo(finInfo,finButton);
  });*/

  // mobile
  /*
  var viewport_width = document.documentElement.clientWidth;
  if(viewport_width <= 640) {
    // Declare a fragment:
    var fragment = document.getElementsByClassName('pricing-information')[0];
    var fragment2 = document.getElementsByClassName('financing')[0];

    // Append fragment to desired element:
    document.getElementsByClassName('wheel-detail-stage')[0].after(fragment);
    document.getElementById('specs').after(fragment2);
  }*/

  //// snipcart

  var start = document.querySelector('.initiate-add-to-cart');
  var popup = document.querySelector('.open-related-popup');
  var addWheel = document.querySelector('.snipcart-add-wheel-wo-related');
  var addWheelAndAccessory = document.querySelector('.snipcart-add-wheel');

  // related items popup functionality
  $('.related-items li input').change(function(){
    let checkbox = $(this);
    let checkboxData = checkbox.data();

    if(checkbox.is(':checked')) {
      checkbox.addClass('item-information');
    } else {
      checkbox.removeClass('item-information');
    } 

  });

  // related cart functions
  function closePopup(){
    $.magnificPopup.close();
  };

  function clearRelated(){
    $('.related-items li').each(function(){
      $(this).find('input').prop('checked', false).removeClass('item-information');
    });
  }

  function initiatePopup() {
    let isFilled = createVehicleInfo();
    if(isFilled == true) {
      popup.click();
    } 
  }

  function createVehicleInfo() {
    // vehicle information
    let vehicle = '<b>What is the Year, Make and Model of your vehicle?</b><br />';
    let modification = '<b>Any modifications to the vehicle?</b><br />';
    let description = '';
    let product = document.querySelectorAll('.item-information');
    // values
    let ymm = document.getElementsByName('ymm')[0];
    let mod = document.getElementsByName('modification')[0];

    if(ymm.value != '' && mod.value != '') {

      for(let i=0; i < product.length; i++) {
        product[i].dataset.itemCustom1Value = ymm.value;
        product[i].dataset.itemCustom2Value = mod.value;
      }
      ymm.classList.remove('missing');
      mod.classList.remove('missing');

      return true;

    } else if (ymm.value == '') {
      
      ymm.classList.add('missing');

    } else if (mod.value == '') {
      ymm.classList.remove('missing');
      mod.classList.add('missing');
      return false;

    }
  }

  function buildItems(description){
    let products = document.querySelectorAll('.item-information');

    let items = [];
    for (let i=0; i < products.length; i++){
      let product = products[i].dataset;
      let item = {};
      let custom1 = {};
      let custom2 = {};
      item.id = product.itemId;
      item.price = product.itemPrice;
      item.url = product.itemUrl;
      item.description = product.itemDescription;
      item.image = product.itemImage;
      item.name = product.itemName;
      item.quantity = product.itemQuantity;
      item.customFields = [];
        // ymm
        custom1.name = product.itemCustom1Name;
        custom1.operation = '';
        custom1.value = product.itemCustom1Value;
        custom1.type = 'textbox';
        custom1.required = true;
        // mods
        custom2.name = product.itemCustom2Name;
        custom2.operation = '';
        custom2.value = product.itemCustom2Value;
        custom2.type = 'textbox';
        custom2.required = true; 
      item.customFields = [custom1,custom2];
      items.push(item);
    }

    return items;
  }

  // snipcart code
  document.addEventListener('snipcart.ready', () => {
    // You can safely use window.Snipcart here
    if(start) {
      start.addEventListener('click', initiatePopup);
    }
    $('.add-to-cart').click(function(){
      let products = buildItems();      
      checkCart(products);    
    });

    addWheel.addEventListener('click', addToCartWithOutRelated);
    addWheelAndAccessory.addEventListener('click', addToCartWithRelated);

    function addToCartWithRelated() {
      console.log('is related');
      closePopup();
      submitToCart();
    }
  
    function addToCartWithOutRelated() {
      console.log('not related');
      closePopup();
      clearRelated();
      submitToCart();
    }

    function submitToCart() {
      let description = createVehicleInfo();
      console.log();
      if (description == true) {
        let products = buildItems(description);
        console.log('this is build products: ',products);
        checkCart(products);
      }
    }
  
    function checkCart(products) {
      let cartList = Snipcart.store.getState().cart.items.items;
      let idList = cartList.map(e => ({id:e.id,uniqueId:e.uniqueId,quantity:e.quantity}));
      let updateList = [];
      let newList = [];
  
      for (let i = 0; i < products.length; i++ ){
        let itemChecked = cartList.find(e => e.id === products[i].id);
        if(itemChecked) {
          updateList.push(products[i]);
        } else {
          newList.push(products[i]);
        }
      }
      
      // update cart
      if(updateList.length) {
        updateCart(updateList,idList);
      }
      
      // add to cart
      addToCart(newList);
    }
    
    function addToCart(items) {
      for(var i = 0; i < items.length; i++) {
        Snipcart.api.cart.items.add(items[i]);
      }
      Snipcart.api.theme.cart.open();
    }
  
    function updateCart(items,cart) {
      for(var i = 0; i < items.length; i++) {
        let cartMatch = cart.find(e => e.id === items[i].id);
        let qty = parseInt(cartMatch.quantity) + parseInt(items[i].quantity);
        let update = {
          uniqueId: cartMatch.uniqueId,
          quantity: qty};
        Snipcart.api.cart.items.update(update);
      }
      Snipcart.api.theme.cart.open();
    }
  
  });
  
} // end wheels


// helpful functions
function trimString(obj){
  var L = (obj.length -1);
  obj = obj.substring(0, L);
  return obj;  
}
function removeVal(obj,value){
   var arrObj = obj.split(',')
   if(arrObj.length > 1){
   for( var i = 0; i < arrObj.length; i++){ 
           if ( arrObj[i] === value) {
                  arrObj.splice(i, 1); 
           }
   }
   obj = arrObj.join(",")
   }else{
        obj ='';
   }
   return obj;
}