$('#gallery-main-image').magnificPopup({
  delegate: 'a',
  type: 'image',
  gallery: {
    enabled: true,
    navigateByImgClick: true,
    preload: [0, 1]
  }
});

$('#gallery-main-image').slick({
  adaptiveHeight: false,
  arrows: false,
  asNavFor: '#gallery-thumbs',
  fade: true,
  lazyLoad: 'ondemand'
});
$('#gallery-thumbs').slick({
  arrows: false,
  asNavFor: '#gallery-main-image',
  focusOnSelect: true,
  infinite: false,
  slidesToShow: 5,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        dots: true,
        slidesToScroll: 3,
        slidesToShow: 3
      }
    }
  ]
});
