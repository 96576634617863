// Insert vehicle image into vehicle bar
function insertVehicleImage() {
  var vehicleData = $('.vehicleData').data();
  var getVehicle = '//iconfigurators.app/api/?function=getVehiclePreview';
  getVehicle += '&year=' + vehicleData.year;
  getVehicle += '&make=' + vehicleData.make;
  getVehicle += '&model=' + vehicleData.model;
  getVehicle += '&submodel=' + vehicleData.submodel;
  // getVehicle += '&option=' + vehicleData.option;
  getVehicle += '&key=' + vehicleData.key;
  $.getJSON(getVehicle, function (data) {
    if (data.result === 'failed') {
      console.error(data.message);
      return;
    }
    if (data.vehicles[0] !== undefined) {
      var returnedImgUrl = data.vehicles[0].previewImage;
      var returnedImg = '<img ';
      returnedImg += 'src="' + returnedImgUrl + '"';
      returnedImg +=
        'alt="' + vehicleData.year + ' ' + vehicleData.make + ' ' + vehicleData.model + '"';
      returnedImg += '/>';
      $('.vehicleData').append(returnedImg);
    }
  });
}

if ($('.setVehicleImg').length > 0) {
  insertVehicleImage();
}
